@import "./colors.scss";
md-toast {
  z-index: 110;

  .md-toast-content {
    background-color: $side-nav-gray !important;
  }

  .md-button {
    background-color: $red !important;
    padding         : 0 13px;
    min-height      : 27px;
    font-size       : 14px;

    &:hover {
      background-color: $dark-red !important;
    }
  }

  .md-highlight {
    color           : $white !important;
    background-color: $green !important;

    &:hover {
      background-color: $dark-green !important;
    }
  }
}

.learnMoreModal {
  outline: none;
  border: none !important;
}
//media queries for desktop
@media only screen and (min-width: 1280px) {
  .confirmDeactivateDialog,
  .confirmMergeDialog {
    left     : 100px;
    max-width: 700px;
  }

  .corDialog {
    max-height: 450px;
  }
}
@media only screen and (max-width: 1280px) {
  .confirmDeactivateDialog,
  .confirmMergeDialog {
    height   : 100%;
    width    : 100%;
    max-width: none;
  }
}
