$dark-green: #008454;
$green: #00A569;
$lt-green: #e6fff6;
$light-green: #EFF9F6;
$brightGreen: #00D889;
$orange: #F5A623;
$mustard: #F1C21A;
$red: #D03702;
$dark-red: #962000;
$grey1: #FAFAFA;
$grey1andahalf: #E8E8E8;
$grey2: #D3D3D3;
$grey2andahalf: #888;
$grey3: #60605D;
$grey4: #4A4A4A;
$grey5: #141414;
$golden-amber: #dad44a;
$silver-mist: #E7E7E7;

$side-nav-gray: #313443;

$header-grey: #403F40;
$para-grey: #4A4A4A;

$white: #FFF;
$black: #000;
