/**
 *  Do not remove the comment below. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
// endbower
@import '../app/styles/buttons.scss';
@import '../app/styles/colors.scss';
@import '../app/styles/layout.scss';
@import '../app/styles/fonts.scss';
@import '../app/styles/dialogs.scss';
@import '../app/styles/filters.scss';
@import '../app/styles/forms.scss';
@import '../app/styles/images.scss';
@import '../app/styles/mobileViews.scss';
@import '../app/styles/tables.scss';
@import '../app/styles/text.scss';
@import '../app/styles/form-field.scss';
@import '../app/styles/form';

@font-face {
  font-family: 'roboto_slabregular';
  src: url('../fonts/RobotoSlab-Regular-webfont.eot');
  src: url('../fonts/RobotoSlab-Regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/RobotoSlab-Regular-webfont.woff') format('woff'),
       url('../fonts/RobotoSlab-Regular-webfont.ttf') format('truetype'),
       url('../fonts/RobotoSlab-Regular-webfont.svg#roboto_slabregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Work Sans';
  src: url('../fonts/WorkSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Work Sans Medium';
  src: url('../fonts/WorkSans-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Work Sans Bold';
  src: url('../fonts/WorkSans-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@import "styles/colors";
// @font-face {
//   font-family: 'Material Icons';
//   font-style: normal;
//   font-weight: 400;
//   src: url("../../bower_components/material-design-iconfont/iconfont/MaterialIcons-Regular.eot");
//   /* For IE6-8 */
//   src: local('Material Icons'), local('MaterialIcons-Regular'), url("../../bower_components/material-design-iconfont/iconfont/MaterialIcons-Regular.woff2") format('woff2'), url("../../bower_components/material-design-iconfont/iconfont/MaterialIcons-Regular.woff") format('woff'), url("../../bower_components/material-design-iconfont/iconfont/MaterialIcons-Regular.ttf") format('truetype');
// }
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}
html {
  font-family: 'roboto_slabregular', serif;
}
[layout=row] {
  flex-direction: row;
}
.overlay{
  width: 100%;
  background: $white;
  opacity: 0.5;
  pointer-events: none;
  height: 100%;
  position: fixed;
  z-index: 999;
}
.noScroll{
  overflow: hidden;
  pointer-events: none;
}
.learn-more {
  margin-bottom: 2px;
}
.learnMoreModal {
  width: 750px;

  .add-margin-top {
    margin-top: 20px;
  }
  .make-bold {
    font-weight: bold;
  }
}

// Mixins
@mixin flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
@mixin inline-flex{
  display: -ms-inline-flexbox;
  display: inline-flex;
}
@mixin justify-content($align){
  @if $align == 'space-between'{
    -ms-flex-pack: justify;
  } @else if $align == 'flex-start'{
    -ms-flex-pack: start;
  } @else if $align == 'flex-end'{
    -ms-flex-pack: end;
  } @else if $align == 'center' {
    -ms-flex-pack: center;
  } @else if $align == 'space-around' {
    -ms-flex-pack: distribute;
  }
  -webkit-box-pack: $align;
  -moz-box-pack: $align;
  -webkit-justify-content: $align;
  justify-content: $align;
}
@mixin align-items($align){
  @if $align == 'flex-start'{
    -ms-flex-pack: start;
  } @else if $align == 'flex-end'{
    -ms-flex-pack: end;
  } @else if $align == 'center' {
    -ms-flex-pack: center;
  } @else{
    -ms-flex-pack: $align;
  }
  -webkit-box-align: $align;
  -moz-box-align: $align;
  -ms-flex-align: $align;
  -webkit-align-items: $align;
  align-items: $align;
}
@mixin flex-direction($align){
  @if $align == 'row' {
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
  }
  -ms-flex-direction: $align;
  -webkit-flex-direction: $align;
  flex-direction: $align;
}
@mixin flex-wrap($wrap){
  @if $wrap == 'wrap' {
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -ms-flex-wrap: wrap;
  } @else{
    -ms-flex-wrap: $wrap;
    -webkit-flex-wrap: $wrap;
  }
  flex-wrap: $wrap;
}
@mixin transition($property, $duration, $timing){
  webkit-transition: $property $duration $timing;
  -moz-transition: $property $duration $timing;
  -o-transition: $property $duration $timing;
  -ms-transition: $property $duration $timing;
  transition: $property $duration $timing;
}
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 1em;
    background: $bgColor;
  }
}



/**
 *  Do not remove the comment below. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
// endinjector
