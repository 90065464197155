@use '@angular/material' as mat;
@use "../app/styles/colors.scss";

@include mat.core();

$msix-palette: (
  50: lighten(#00A569, 30%),
  100: lighten(#00A569, 20%),
  200: lighten(#00A569, 10%),
  300: #00A569,
  400: darken(#00A569, 10%),
  500: darken(#00A569, 20%),
  600: darken(#00A569, 30%),
  700: darken(#00A569, 40%),
  800: darken(#00A569, 50%),
  900: darken(#00A569, 60%),
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

$my-palette: $msix-palette;
$my-primary: mat.define-palette($my-palette, 300);
$my-accent: mat.define-palette($my-palette, 200);

$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
  ),
));

@mixin msix-form-fields-theme() {
  .mat-mdc-form-field .mat-mdc-floating-label[ng-reflect-floating='true'] {
    mat-label {color: colors.$green !important}
  }
  .mat-mdc-form-field .mat-mdc-floating-label[ng-reflect-floating='false'] {
    color:  #60605D !important;
  }
  .mdc-text-field--invalid  {
    .mat-mdc-floating-label[ng-reflect-floating='true'] {
      mat-label {color: colors.$red !important};
    }

  }

}

@include mat.all-component-themes($my-theme);
@include msix-form-fields-theme()
