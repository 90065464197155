@use "./colors.scss";
@use "./fonts.scss";
.center {
  display       : flex;
  flex-direction: column;
  align-items   : center;
}

.main-border {
  margin-top: 64px;
}

.main-border-mobile {
  margin-top: 56px;
}

.main-padding {
  padding-top: 36px;
}

.main-padding-mobile {
  padding-top: 1px;
}

.md-content {
  border-radius: 5px;
  // padding: 10px !important;
  margin       : 0 !important;
}

.layout-column {
  margin: 0;
  display: flex;
  flex-direction: column;
}


.right-align {
  margin-left: auto; 
  margin-right: 0;
}

.layout-row {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
}

.layout-flex-row {
  display: flex;
  flex-direction: row;
}

.layout-flex-column {
  display: flex;
  flex-direction: column;
}

.flex-25 {
  display: flex;
  flex: 25%
}

.flex-75 {
  display: flex;
  flex: 75%
}

div {
  .background-grey1 {
    background     : colors.$grey1;
    background-size: 100%;
    top            : 0;
    bottom         : 0;
    right          : 0;
    left           : 0;
  }

  .background-white {
    background: colors.$white !important;
  }

  .background-gray {
    background: colors.$grey1 !important;
  }

  .background-green-opacity-20 {
    background: rgba(colors.$green, .20);
  }

  .background-cover {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size      : cover;
    background-repeat    : no-repeat;
    background-attachment: fixed;
    background-position  : center;
    /* width                : 100%; */
    height               : auto;
    overflow             : hidden;
    min-height           : 100%;
    /* position             : absolute; */
    top                  : 0;
    left                 : 0;
    border               : none;
  }
  .background-awesome {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size      : cover;
    background-repeat    : no-repeat;
    background-attachment: fixed;
    background-position  : center;
    width                : 100%;
    height               : auto;
    overflow             : auto;
    min-height           : 100%;
    position             : absolute;
    top                  : 0;
    left                 : 0;
    border               : none;
  }

  mat-toolbar {
    border-radius: 4px 4px 0px 0px;     
    min-height: 50px;
    max-height: 50px; 
    box-sizing: border-box;
    display: flex;    
    flex-direction: column;
    position: relative;    
    z-index: 2;    font-size: 20px;    min-height: 64px;   width: 100%;   

  }

  .background-brilliant {
    // background           : colors.$dark-green url('../../assets/images/backgrounds/bg2@2x.png');
    background-size      : cover;
    background-repeat    : no-repeat;
    background-attachment: fixed;
    background-position  : center;
    width                : 100%;
    height               : auto;
    overflow             : auto;
    min-height           : 100%;
    position             : absolute;
    top                  : 0;
    left                 : 0;
  }

  .dark-overlay {
    background-color: rgba(220, 220, 220, 0.3);
    position:absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    min-height:405px;
    place-content:center;
  }
}

.flex-inline {
  display    : flex;
  align-items: center;
}

.flex-top {
  display: flex;
}

.flex-baseline {
  display    : flex;
  align-items: baseline;
}

.flex-container {
  display        : flex;
  justify-content: center;
  align-items    : center;
}

.correspondence-flex-container {
  display        : flex;
  justify-content: left;
  align-items    : center;
}

.flex-child-login {
  flex: 1;
}

.no-overflow-x {
  overflow-x: hidden;
}

.top-margin-10 {
  margin-top: 10px;
}

.left-margin-14 {
  margin-left: 14px;
}

.left-right-margin-14 {
  margin-right: 8px;
  margin-left : 14px;
}

.layout-margin {
  margin-left : 15px;
  margin-right: 15px;
}

.layout-margin-worklist-module {
  margin: 15px 30px;
}

.layout-padding-worklist-module {
  padding: 20px 20px 30px;
}

.layout-padding-worklist-submodule {
  padding: 20px 15px 30px;
}

.layout-padding-l-r-10 {
  padding-left : 10px;
  padding-right: 10px;
}

.layout-padding-l-r-17 {
  padding-left : 17px;
  padding-right: 17px;
}

.layout-padding-l-14 {
  padding-left: 14px;
}

.layout-padding-r-14 {
  padding-right: 14px;
}

.layout-padding-bottom-15 {
  padding-bottom: 15px;
}

.worklist-commentbox-padding {
  margin-bottom: 20px;
}

.commentbox-instructions {
  min-height: initial !important;
}

.flexSpaceBetween {
  display        : flex;
  justify-content: space-between;
  align-items    : center;
}

.flexCenter {
  display        : flex;
  justify-content: center;
}

.flexSpaceBetweenTop {
  display        : flex;
  justify-content: space-between;
}

hr {
  border    : 0;
  padding   : 0;
  margin    : 0;
  background: colors.$grey2;

  &.thickHr {
    padding: 1px;
    height : 2px;
  }

  &.thinHr {
    margin-bottom: 0;
    margin-top   : 12px;
    height       : 1px;
  }

  &.blackHr {
    height    : 1px;
    background: colors.$grey5;
  }
}

md-divider.search-results-divider {
  border-color: colors.$grey3;
}
//changes the color of the tabs in home page

md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active,
md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon,
md-tabs.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon,
md-tabs.md-default-theme.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused,
md-tabs.md-default-theme.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon {
  color: colors.$grey4;
}

md-tabs md-ink-bar,
md-tabs.md-default-theme md-ink-bar {
  background-color: colors.$green;
}

md-switch {
  &.md-default-theme.md-checked .md-thumb {
    background-color: colors.$white;
  }

  &.md-checked .md-thumb {
    background-color: colors.$white;
  }

  &.md-default-theme.md-checked .md-bar {
    background-color: colors.$green;
  }

  &.md-checked .md-bar {
    background-color: colors.$green;
  }

  .md-bar {
    height       : 22px;
    top          : 1px;
    left         : 3px;
    border-radius: 22px;
    width        : 38px;
  }

  .md-default-theme .md-bar {}

  .md-bar {
    background-color: colors.$grey2;
  }

  .md-thumb {
    left: 4px;
  }
  //.md-label {
  //  position: relative;
  //  right: 43px;
  //}
  .md-container {
    //left: 265px;
  }
}
//changes checkbox colors

md-checkbox {
  &.md-checked .md-icon {
    border-color    : colors.$green;
    background-color: colors.$white;
  }

  &.md-default-theme.md-checked .md-icon {
    background-color: colors.$white;
  }

  &.md-checked .md-icon:after,
  &.md-default-theme.md-checked .md-icon:after {
    border-color: colors.$green;
  }
}

md-checkbox .md-container:before {
  background-color: colors.$white;
}

.reassign-page {
  md-checkbox .md-container {
    top: 120% !important;
  }
}

.search-results-desktop-border {
  border-color       : colors.$grey2 !important;
  border-bottom-style: solid !important;
  border-top-style   : solid !important;
  border-width       : 1px !important;
  background-color   : colors.$grey1 !important;
}

.layout-padding-t {
  padding-top: 5px;
  //removes yellow outline on focus in chrome browser
  &:focus {
    outline: none;
  }
}

.layout-padding {
  padding: 5px;
  //removes yellow outline on focus in chrome browser
  &:focus {
    outline: none;
  }
}

.layout-padding-l-r-b {
  padding: 0 5px 5px;
  //removes yellow outline on focus in chrome browser
  &:focus {
    outline: none;
  }
}

.vertical-align-center {
  vertical-align: middle;
}

.no-margins {
  margin: 0;
}

.no-margins-top {
  margin-top: 0;
}
// removes angular's default pink ripple effect

.md-ripple-container {
  display: none !important;
}

.display-block {
  display: block;
}

.text-bottom {
  vertical-align: text-bottom;
}

.layout-margin-2 {
  margin : 20px 50px 40px;
  padding: 0;
}

.header-offset-70 {
  margin-top: 70px;
}

body {
  background: colors.$grey1;
}

p {
  .color-green {
    color: colors.$green;
  }

  .color-grey2 {
    color: colors.$grey2;
  }
}

.color-green {
  color: colors.$green;
}

.container-overflow {
  overflow-y: scroll;
}

.container-max-height {
  height: 435px;
}

.position-fixed {
  position: fixed;
}

.position-absolute {
  position: absolute;
  bottom: 0;
}

.h1-no-margins {
  margin-bottom: 0;
}

.no-padding {
  padding: 0 !important;
}

.no-bottom-margin {
  margin-bottom: 0;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.no-padding-left {
  padding-left: 0;
}

.no-padding-top {
  padding-top: 0;
}

.no-padding-t-b-r {
  padding: 0 0 0 10px;
}

.no-top-bottom-margin-padding {
  margin-top    : 0;
  margin-bottom : 0;
  padding-top   : 0;
  padding-bottom: 0;
}

.no-top-bottom-right-margin-padding {
  margin-top    : 0;
  margin-bottom : 0;
  margin-right  : 0;
  padding-top   : 0;
  padding-bottom: 0;
}

.no-top-bottom-margin {
  margin-top   : 0;
  margin-bottom: 0;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-top-25 {
  padding-top: 25px;
}

.padding-right-20 {
  padding-right: 20px;
}

.margin-top-bottom-20 {
  margin-bottom: 20px;
  margin-top   : 20px;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.pwReset-container-max-height {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  margin-top: 40px;
  background-color: colors.$white;
  border-radius: 5px;
  width: 30% !important;
  max-height: 425px !important;
  overflow-y: auto;
  overflow-x: hidden;
}
// Progress Indicator below

.progressIndicator {
  position         : fixed;
  height           : 100%;
  width            : 100%;
  align-items      : center;
  z-index          : 110;
  left             : 50vw;
  top              : 50vh;
  -webkit-box-align: center;
  -ms-flex-align   : center;
  align-items      : center;
}

md-progress-circular {
  margin: 0 auto;
}

.float-right {
  float: right;
}

.padding-6px {
  padding: 6px;
}

.padding-left-10 {
  padding-left: 10px;
}
//.worklist-description-text {
//  line-height: 14px;
//  font-size: 12px;
//}
.worklist-header-title-full-underline{
  border-bottom: 2px solid colors.$grey2andahalf;
}

.worklist-header-titles {
  border-bottom: 2px solid colors.$grey2andahalf;
  margin-bottom: 0;
}
.no-border{
  border-bottom: none;
}
.header-pad{
  padding-left: 6px;
} 
//.worklist-commentbox-instructions {
//  line-height: 12px !important;
//  // using important to override small text style
//}
//.worklist-accordion-section2 {
//  padding-left: 37px !important;
//  padding-right: 37px !important;
//}
//}

.worklist-validate-accordion {
  padding-left : 20px;
  padding-right: 20px;
}

.worklist-history-accordion {
  padding: 20px;

  .row {
    padding: 10px 0 10px 6px;
  }
}

.worklist-id-history-accordion{
  padding: 0 20px;
}
.worklist-id-items{
  padding: 0 20px 20px 20px;
}

.center-text {
  text-align: center;
}

.top-bottom-margin-4 {
  margin-top   : 4px;
  margin-bottom: 4px;
}

.top-bottom-margin-5 {
  margin-top   : 5px;
  margin-bottom: 5px;
}

.top-bottom-margin-8 {
  margin-top   : 8px;
  margin-bottom: 8px;
}

.top-bottom-margin-10 {
  margin-top   : 10px;
  margin-bottom: 10px;
}

.top-bottom-margin-15 {
  margin-top   : 15px;
  margin-bottom: 15px;
}

.top-bottom-margin-16 {
  margin-top   : 16px;
  margin-bottom: 16px;
}

.top-bottom-padding-2 {
  padding-top   : 2px;
  padding-bottom: 2px;
}

.top-bottom-padding-5 {
  padding-top   : 5px;
  padding-bottom: 5px;
}

.top-bottom-padding-8 {
  padding-top   : 8px;
  padding-bottom: 8px;
}

.top-bottom-padding-10 {
  padding-top   : 10px;
  padding-bottom: 10px;
}

.left-right-margin-8 {
  margin-left : 8px;
  margin-right: 8px;
}

.left-right-margin-20 {
  margin-left : 20px;
  margin-right: 20px;
}

.left-right-margin-30 {
  margin-left : 30px;
  margin-right: 30px;
}

.left-right-margin-50 {
  margin-left : 50px !important;
  margin-right: 50px !important;
}

.left-right-padding-15 {
  padding-right: 15px;
  padding-left : 15px;
}

.left-right-padding-4 {
  padding-right: 4px;
  padding-left : 4px;
}

.left-right-padding-20 {
  padding-right: 20px !important;
  padding-left : 20px !important;
}

.left-padding-6 {
  padding-left: 6px;
}

.left-padding-8 {
  padding-left: 8px;
}

.left-padding-30 {
  padding-left: 30px;
}

.no-left-padding {
  padding-left: 0;
}

.no-left-right-padding {
  padding-left : 0;
  padding-right: 0;
}

.worklist-split-children-bottom-border {
  border-bottom: 1px solid colors.$grey2;
}

.worklist-left-right-bottom-border {
  border-right : 1px solid colors.$grey2;
  border-left  : 1px solid colors.$grey2;
  border-bottom: 1px solid colors.$grey2;
}

.worklist-split-children-bottom-border-green {
  border-bottom: 2px solid colors.$green;
  border-right : 1px solid colors.$grey2;
  border-top   : 1px solid colors.$grey2;
  border-left  : 1px solid colors.$grey2;
}

.bottom-border-green {
  border-bottom: 2px solid colors.$green;
}

.results-border {
  border-bottom: 1px solid colors.$grey2;
}

.worklist-box-border {
  border: 1px solid colors.$grey2andahalf;
}

.worklist-box-bottom-border {
  border-bottom: 1px solid colors.$grey3;
}

.worklist-confirm-commentbox-padding {
  padding: 10px;
}

.height-45 {
  min-height: 45px;
}

.font-20 {
  font-size: 20px !important;
}

.reassign-admin-list {
  padding: 0 20px;
  margin : 0 10px;
}

.worklist-history-search-box {
  margin: 25px 0 0;
}

md-toolbar.md-default-theme:not(.md-menu-toolbar),
md-toolbar:not(.md-menu-toolbar) {
  background-color: colors.$green;
  color           : colors.$white !important;
}

mat-toolbar.md-default-theme:not(.mat-menu-toolbar),
mat-toolbar:not(.mat-menu-toolbar) {
  background-color: colors.$green !important;
  color           : colors.$white !important;
}

md-toast {
  position: fixed;
  overflow: hidden;
}
//tooltip menu

.md-open-menu-container {
  z-index: 110;
}

.custom-export {
  min-width: 0px !important;
  max-width: 91px !important;
}

// Media Queries below
@media only screen and (max-width: 600px) {
  // background for phone
  .background-awesome {
    // background       : colors.$dark-green url('../../assets/images/backgrounds/bg@2x.png');
    background       : url("../../assets/images/backgrounds/bg_d@2x.jpg") no-repeat center center fixed;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  div {
    .background-creative {
      background-size: cover;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;
      width: 100%;
      height: auto;
      overflow: hidden;
      min-height: 100%;
      top: 0;
      left: 0;
      border: none;
    }
  }

  .container-max-height {
    height: 475px;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1280px) {
  // background for tablet
  .background-awesome {
    //background: colors.$dark-green url('../../assets/images/backgrounds/bg_t.png');
    background         : url("../../assets/images/backgrounds/bg_d@2x.jpg") no-repeat center center fixed;
    background-repeat  : no-repeat;
    background-position: left;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  md-tabs-wrapper {
    margin-bottom: 20px;
  }

  .container-max-height {
    height: 800px;
  }

  .color-white {
    color: colors.$white;
  }

  .layout-padding-l-10 {
    padding-left: 10px;
  }

  div {
    .background-creative {
      background-size: cover;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;
      width: 100%;
      height: auto;
      overflow: hidden;
      min-height: 100%;
      top: 0;
      left: 0;
      border: none;
    }
  }
}
@media only screen and (min-width: 1280px) {
  // background for desktop, using !important to override default background
  .background-awesome {
    background         : url("../../assets/images/backgrounds/bg_d@2x.jpg") no-repeat center center fixed;
    background-repeat  : no-repeat;
    background-position: left;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  //space for locked side nav
  [ui-view="main"] > *:first-child {
    padding-left: 220px;
  }
  //no sidenav on login or password reset page, using !important so it overrides above css
  .admin-search-results-page,
  .login-page,
  .password-update-page,
  .pwReset-page {
    padding-left: 0 !important;
  }

  md-tabs-wrapper {
    display: none;
  }

  .container-max-height {
    height: 800px;
  }

  .studentDetails-page .consolidatedPage {
    margin-bottom: 40px;
  }

  .layout-padding-l-10 {
    padding-left: 10px;
  }

  md-progress-circular {
    //pushes progress indicator to account for fixed sidenav
    padding-right: 220px;
    padding-left : 220px;
  }

  .color-white {
    color: colors.$white;
  }

  md-radio-button:focus {
    outline: none;
    border : 0;
  }
}
