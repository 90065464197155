@use "./colors.scss";

h1 {
  font-family: 'roboto_slabregular' !important;
  font-weight: 400 !important;
  font-size: 30px !important;
  color: colors.$header-grey !important;
  line-height: 30px !important;
  margin: 0px !important;
  -webkit-font-smoothing: antialiased;
}
h2 {
  font-family: 'roboto_slabregular', serif !important;
  font-weight: 400 !important;
  font-size: 24px !important;
  color: colors.$header-grey;
  line-height: 26px !important;
  margin: 0px !important;
  -webkit-font-smoothing: antialiased;
}
h3 {
  font-family: 'roboto_slabregular', serif !important;
  font-weight: 100;
  font-size: 18px !important;
  color: colors.$para-grey;
  line-height: 21px !important;
  margin: 0px !important;
  -webkit-font-smoothing: antialiased;
}
h4 {
  font-family: 'roboto_slabregular', serif !important;
  font-weight: 400 !important;
  font-size: 15px;
  color: colors.$para-grey !important;
  line-height: 20px !important;
  margin: 0px !important;
  -webkit-font-smoothing: antialiased;
}
li {
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  // color: colors.$para-grey;
  line-height: 19px !important;
  margin: 0px !important;
  -webkit-font-smoothing: antialiased;
}
a {
  display: inline-block !important;
  cursor: pointer !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  color: colors.$green !important;
  letter-spacing: 0.41px !important;
  /*line-height: 37px;*/
  text-decoration: none !important;
  vertical-align: middle !important;
  -webkit-font-smoothing: antialiased;

  &.line-height-20 {
    line-height: 20px;
  }

  &.line-height-0 {
    line-height: 0px;
  }

  &:focus, &:hover{
    color: colors.$dark-green !important;
  }
  span{
    border-bottom: 2px solid colors.$green;
    line-height: 27px;
  }
}
.removeUnderline{
  border-bottom: 0 !important;
  line-height: 0 !important;
}

@media only screen and (max-width: 600px) {

  .a-grey3 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: colors.$grey3;
    letter-spacing: 0.41px;
    line-height: 37px;
    text-decoration: none;
    vertical-align: middle;
    &:hover {
      color: colors.$grey4;
    }
  }
}

@media only screen and (min-width: 600px) {

  .a-grey3 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: colors.$white;
    letter-spacing: 0.41px;
    line-height: 37px;
    text-decoration: none;
    vertical-align: middle;
    &:hover {
      color: colors.$grey1;
    }
  }
}

.validation {
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
  font-size: 17px !important;
  color: colors.$red !important;
  letter-spacing: 0;
  line-height: 20px !important;
}

.mfavalidation {
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: colors.$red !important;
  letter-spacing: 0;
  line-height: 20px !important;
}

strong {
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: colors.$para-grey;
  line-height: 24px;
}

.filterPfsElIepQadGradeLevelIssues .example-full-width label {
  color: rgba(0,0,0,0.54) !important;
  font-weight: 700;
}

// .filterPfsElIepQadGradeLevelIssues .input-selected label {
//   color: #00A569 !important;
//   font-weight: 700;
// }

label {
  font-family: 'Work Sans', sans-serif !important;
  color: colors.$grey4 !important;
  letter-spacing: 0px !important;
  line-height: 24px !important;
  -webkit-font-smoothing: antialiased !important;
  &:active {
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    color: colors.$para-grey;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
  }
}

.student-details, .userDetails-page, .myaccount-page, .setChallengeQuestions-page, .answerCQs-page {
  .mat-mdc-form-field .mat-mdc-floating-label[ng-reflect-floating='true'] {
    mat-label {color: #141414 !important}
  }
}

input {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  vertical-align: baseline;
  border: 1px solid colors.$grey4;
  padding: 12px 0px 8px 6px;
  width: 100%;
  height: 30px;
  &:focus, .mat-input-has-value {
    border: 2px solid colors.$grey4;
    padding: 12px 0px 8px 6px !important;
    width: 100% !important;
  }
}

.mdc-line-ripple--active::after {
  display: none;
}

block, .block {
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 36px;
  text-transform: uppercase;
  color: colors.$grey4;
  &.block-gray3 {
    color: colors.$grey3;
  }
  .heavy{
    font-weight: 1000;
  }
}

.block2 {
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 36px;
  color: colors.$grey4;
}

.filterHeader, filterHeader {
  font-family: 'Work Sans', sans-serif;
  font-weight: 700 !important;
  font-size: 14px !important;
  color: colors.$grey4;
  letter-spacing: 0;
  line-height: 24px !important;
  -webkit-font-smoothing: antialiased;
}

.filterText, filterText {
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: colors.$grey4 !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  -webkit-font-smoothing: antialiased !important;
}

.camelcase {
 text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

p {
  
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
  font-size: 15px;
  margin: 0px !important;
  -webkit-font-smoothing: antialiased;

  .tight {
    font-family: 'Work Sans', sans-serif !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    color: colors.$para-grey !important;
    line-height: 21px !important;
    -webkit-font-smoothing: antialiased;
  }

  .small {
    font-family: 'Work Sans', sans-serif !important;
    font-weight: 400 !important;
    line-height: 14px !important;
    color: colors.$grey3 !important;
    font-size: 12px !important;

    &.small-grey2andahalf {
      color: colors.$grey2andahalf;
    }
  }

  .required-asterisk {
    color: colors.$green !important;
  }

  .error-asterisk {
    color: colors.$red !important;
  }

  .color-green {
    color: colors.$green;
  }

  .bold-text {
    font-weight: bold;
  }

  .color-grey2 {
    color: colors.$grey2;
  }
}
