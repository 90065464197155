@import "./colors.scss";

button {
  border: none;
  &:enabled {
    cursor: pointer;
  }
}

.md-button.md-default-theme:not([disabled]):hover,
.md-button:not([disabled]):hover,
a.md-button.md-default-theme:not([disabled]):hover,
a.md-button:not([disabled]):hover {
  background-color: $dark-green;
  color: $white;
}

.md-button.md-default-theme:not([disabled]).md-focused,
.md-button:not([disabled]).md-focused {
  background-color: $green;
}

.md-button {
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-size: 15px;
  color: $white;
  letter-spacing: 1.75px;
  line-height: 36px;
  text-transform: uppercase;
  border-radius: 20px;
  padding: 0 25px;
  margin-top: 15px;
  margin-bottom: 15px;

  &:enabled {
    background-color: $green;
    cursor: pointer;
  }

  &:disabled {
    border: 2px solid #4A4A4A;
    color: #4A4A4A;
    background-color: $white;
  }

  &:not([disabled]) &:hover {
    background-color: $dark-green;
    color: $white;
  }

  &:focus {
    /* background-color: $green;
    color           : $white;*/
    outline: -webkit-focus-ring-color auto 5px;
    border: black solid 2px !important;
  }
}

.learnMoreModal {
  .md-button:focus {
    outline: 0 !important;
    border: none !important;
  }
}

.md-icon-button {
  background-color: #00A569 !important;
  border: none;
  color: $white;

  &:not([disabled]) &:hover {
    background-color: #00A569 !important;
  }
}

.clear-button {
  display: inline-block;
  cursor: pointer;
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: $green;
  letter-spacing: 0.41px;
  line-height: 37px;
  text-decoration: none;
  vertical-align: middle;
  background-color: transparent;
  border: none;
  text-align: left;

  &.line-height-20 {
    line-height: 20px;
  }

  &:focus,
  &:hover {
    color: $dark-green;
  }

  span {
    border-bottom: 2px solid $green;
    line-height: 27px;
  }
}

.mobile-worklist-remove-button {
  @extend .md-button;
  padding: 0 8px;
  margin-bottom: 0;
  margin-top: 0;
}

.worklist-remove-button {
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: $green;
  letter-spacing: 1.75px;
  line-height: 36px;
  text-transform: uppercase;
  border-radius: 20px;
  padding: 0 15px;
  margin-top: 15px;
  margin-bottom: 15px;

  &:enabled {
    background-color: $white;
    border: 1px solid $green;
  }

  &:disabled {
    background-color: $grey4;
    color: $white  !important;
  }

  &:focus,
  &:not([disabled]) &:hover {
    background-color: $dark-green;
    color: $white  !important;
  }
}

.worklist-reject-button {
  color: $green;

  &:enabled {
    background-color: $white;
    border: 1px solid $green;
  }

  &:disabled {
    background-color: $white;
    border: 2px solid #4A4A4A;
    color: #4A4A4A;
  }

  &:focus,
  &:not([disabled]) &:hover {
    color: $white  !important;
    background-color: $dark-green;
    border: 1px solid $dark-green;
  }
}

.md-tab {
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 1px;
  line-height: 18px;
  text-transform: uppercase;
  /* border-radius: 20px; */
  padding: 0 15px;
  margin-top: 15px;
  margin-bottom: 15px;

  &:enabled {
    color: $grey4;
  }

  &:disabled {
    color: $grey2;
  }

  &:focus,
  &:hover,
  &:not([disabled]).md-focused {}
}

.accordion-icon {
  color: $green;
  padding-right: 20px;
}

.confirmButton {
  width: 200px;
}

/* Tabs styling */
.customTabs {
  cursor: pointer;
  margin-top: 20px;

  .customTab {
    outline: none;
    padding: 6px 0;

    &:focus {
      outline: -webkit-focus-ring-color auto 5px !important;
    }
  }

  .tabCount {
    background-color: $green;
    border-radius: 999px;
    letter-spacing: 0;
    padding: 0 4px;
    font-size: 11px;
    font-weight: 900;
    min-width: 12px;
    color: $white;
    line-height: 15px;
    margin-top: -22px;
  }
}

.tabActive {
  border-bottom: 4px solid $green;
  padding-bottom: 2px !important;
}

.tabDisabled {
  opacity: .5;
  cursor: default;
}

/* give anchor tag appearance of being disabled */
.aDisabled {
  pointer-events: none;

  span {
    color: $grey3;
    border-bottom-color: $grey3;
  }
}