@import "./colors.scss";
.mobile-view {
  margin-top: 40px;
  color     : $grey3;
  min-height: 300px;

  .report-summary {
    margin-top   : 0 !important;
    margin-bottom: 40px !important;
    font-weight  : 500;
  }

  .input-label {
    color         : $grey3;
    text-transform: uppercase;
  }

  .report-summary b {
    color         : $grey3;
    text-transform: uppercase;
  }

  .md-input-container {
    width        : 100%;
    margin-top   : 5px;
    padding      : 0;
    margin-bottom: 40px;

    label {
      opacity : 1;
      position: initial;
      color   : grey3 !important;
    }

    md-select {
      width   : 100%;
      position: relative;
      height  : 52px;

      label {
        display: none;
      }

      md-select-value {
        border    : 1px solid $grey2 !important;
        padding   : 0;
        background: $white;
        color     : $grey4 !important;

        span:first-child {
          padding-left: 10px;
        }

        .md-select-icon {
          text-align : center;
          padding    : 0 28px !important;
          margin     : 0;
          border-left: 1px solid $grey2;
          height     : 50px;
          width      : 0;
          position   : absolute;
          right      : 0;
          top        : 2px;

          &:after {
            top      : 14px;
            left     : -7px;
            color    : $green;
            font-size: 16px;
          }
        }
      }

      &:not([disabled]):focus .md-select-value {
        padding-bottom: 5px;
      }
    }
  }

  md-input-container.md-input-focused:not([md-no-float]) .md-select-placeholder span:first-child {
    transform: none;
    position : absolute;
    top      : 14px;
  }

  .radio-group-clearfix {
    overflow     : hidden;
    margin-bottom: 40px;

    md-radio-group {
      md-radio-button {
        float : left;
        margin: 0 20px 0 0;
      }

      &.md-default-theme.md-focused :not(:empty) .md-checked .md-container:before,
      md-radio-group.md-focused :not(:empty) .md-checked .md-container:before {
        background-color: none;
      }
    }
  }

  .stats-wrapper {
    border-top      : 1px solid $grey2;
    margin          : 0 -15px;
    background-color: $white;

    .stats-item {
      border-bottom: 1px solid $grey2;
      padding      : 20px 0;
      margin       : 0 20px;

      .label {
        font-weight   : 600;
        margin-bottom : 5px;
        text-transform: uppercase;
        font-size     : 16px;
        font-family   : 'Work Sans';
      }

      .value {
        font-size  : 40px;
        font-weight: 900;
        text-align : left;
      }
    }

    &.counts .stats-item {
      margin-left: 40px;
    }

    div {
      .label {
        text-transform: uppercase !important;
      }

      &:first-child {
        .stats-item {
          margin-left: 20px;

          .label {
            font-weight   : 600;
            text-transform: uppercase !important;
          }

          .value {
            font-size: 48px;
          }
        }
      }

      &:last-child {
        .stats-item {
          border: 0;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .mobileReport {
    margin-top: 0 !important;
    position  : fixed;
    top       : 50px;
    height    : calc(100% - 50px);

    .md-content {
      height: 100%;
    }
  }

  .breadcrumb {
    background-color: $grey1 !important;

    span {
      display: none;
    }
  }

  .header {
    background-color: inherit !important;
  }
}
