@import "./colors.scss";
.work-sans-serif {
  font-family: 'Work Sans', sans-serif;
}

.color-mustard {
  color: $mustard !important;
}

.color-red {
  color: $red !important;
}
