@use "./colors.scss";
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

.custom-input-container {
  padding: 0;
  margin: 0;

  &.error {
    padding: 1px 0 12px 12px;
    border-left: 4px solid colors.$red;
    margin-top: 5px;
  }

  .error-message {
    p {
      margin-top: 5px !important;
      color: colors.$red  !important;
    }
  }

  .custom-input {
    margin: 0;
    margin-top: -10px;
    border: 1px solid colors.$grey2andahalf;

    /* padding: 7px 2px; */
    &.md-input-focused,
    &.md-input-has-value {
      border: 2px solid colors.$grey4;
    }

    &.md-input-invalid {
      border: 2px solid colors.$red;
    }

    .md-errors-spacer {
      min-height: 0;
    }

    &.md-input-container-margins {
      margin: 7px 0;
    }

    .md-input {
      padding-top: 13px;
      padding-left: 10px;
    }
  }

  .custom-input-disabled {
    border: 1px solid colors.$grey4;
    /* padding: 7px 2px;
    margin: 5px; */
    width: 97.85%;
    background-color: colors.$grey2;
    color: rgba(0, 0, 0, 0.38);

    .input-tablet-portrait {
      width: 80% !important;
    }

    .input-tablet-landscape {
      width: 95% !important;
    }
  }

  .bottomInput {
    margin-top: -1px;
  }

  .custom-label {
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    color: colors.$grey4;
    letter-spacing: 0.5px;
    line-height: 0;
    padding-top: 8px;
    padding-bottom: 6px;
    margin-left: 10px;
  }

  md-input-container:not(.md-input-invalid).md-input-focused,
  md-input-container:not(.md-input-invalid).md-input-has-value,
  .md-input-invalid {
    .custom-label {
      line-height: 0;
      color: colors.$grey5;
      margin-left: 0;
      transform: translate3d(10px, 20px, 0) scale(.75);
    }

    .select-label {
      transform: translate3d(10px, 15px, 0) scale(.75);
    }
  }

  md-input-container.md-input-focused:not(.md-input-has-value) md-select .md-select-value.md-select-placeholder,
  md-input-container.md-input-focused:not(.md-input-has-value) md-select.md-default-theme .md-select-value.md-select-placeholder {
    color: transparent;
  }

  md-select {
    margin-top: -10px;

    &:not([disabled]):focus .md-select-value {
      border-bottom: none;
    }

    md-select-value {
      border-bottom: none;
      margin-top: 8px;
      margin-left: 8px;

      &.md-select-placeholder {
        color: colors.$grey4;
      }
    }

    md-select-value:first-child {
      color: colors.$grey4;
    }

    &.md-select-input-height {
      height: 40px;
    }
  }
}

.bottom-input {
  margin-top: -1px;
}

/*removes yellow autofill bg color for form inputs*/
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px colors.$white inset;
}

.radio-input .md-checked .search-form-radio-button {
  background-color: colors.$lt-green;
  /*opacity: 0.5;*/
  border-radius: 4px;
}

.dotted-border {
  border-top-style: dashed;
  border-width: 1px;
  border-color: colors.$grey2;

  &:last-child {
    border-bottom-style: dashed;
  }
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.dob-margin {
  margin-left: 8px !important;
}

.dob-margin-neg {
  margin-left: -1px !important;
}

.mdc-text-field__input {
  padding: 12px 0px 8px 6px;
}

.filterMsixUsage,
.recordsExchange {
  .mdc-text-field__input {
    padding-top: 1px !important;
  }
}


mat-checkbox {
  .mdc-checkbox__checkmark {
    color: var(--mdc-checkbox-selected-checkmarke-color, colors.$green);
  }

  .mdc-checkbox__background {
    border-color: colors.$green;
    background-color: var(--mdc-checkbox-selected-icon-color, var(--mdc-theme-secondary, colors.$white));
  }
}

mat-checkbox.custom-input-disabled {
  .mdc-checkbox__background svg {
    color: white !important;
    background-color: gray !important;
  }

  .mdc-checkbox__background {
    border-color: green;
  }
}

::ng-deep mat-dialog-container {
  position: relative;
  top: 15%;
  left: 15%;
  height: 60%;
  width: 80%;
}

// Overrides - END

// Overrides after adding the theme - START

.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: colors.$black;
}

.editAddDialog .mdc-floating-label--float-above {
  margin-top: 12px;
}

.userDetails-state .mdc-floating-label--float-above {
  margin-top: 0px !important;
  margin-left: 3px !important;
}

.newUser-page .mdc-floating-label--float-above {
  margin-top: 7px !important;
}

.myaccount-page {
  .mdc-floating-label--float-above {
    margin-left: 5px !important;
  }

  .response .mdc-floating-label--float-above {
    margin-top: 11px !important;
  }

  .md-input input[disabled] {
    color: colors.$black  !important;
  }

  custom-mat-input {
    input {
      padding: 13px 0px 5px 10px !important;
      width: 98% !important;
    }

    .mat-mdc-form-field .mat-mdc-floating-label[ng-reflect-floating='true'] {
      margin-top: 10px !important;
    }
  }

  .email,
  .state {
    .mdc-text-field {
      border-radius: 0;
    }

    .mat-mdc-form-field-infix {
      margin-top: -9px;
      margin-bottom: -1px;
      margin-right: -9px;
    }

    mat-form-field {
      margin-top: 10px;
      width: 98.5% !important;
    }

    input {
      width: 99.5% !important;
      padding-left: 9px !important
    }
  }

  .email-xs {
    mat-form-field {
      width: 99.2% !important;
    }

    input {
      width: 99% !important;
    }
  }

  .email-md {
    mat-form-field {
      width: 99.25% !important;
    }

    input {
      width: 99% !important;
    }
  }

  .addressLine-md {
    mat-form-field {
      width: 100.5% !important;
    }

    .custom-mat-input-component .mat-input-has-value {
      border: 2px solid #4A4A4A;
      width: 97.5% !important;
    }
  }

  .addressLine-sm {
    .custom-mat-input-component .mat-input-has-value {
      width: 97% !important;
    }
  }

  .state-md {
    mat-form-field {
      width: 99% !important;
    }

    input {
      width: 99% !important;
    }
  }

  .state-xs {
    mat-form-field {
      width: 98.5% !important;
    }

    input {
      width: 99.1% !important;
    }
  }

  .extension {
    mat-form-field {
      width: 96% !important;
    }
  }

  .extension-sm {
    mat-form-field {
      width: 98% !important;
    }
  }
}

.potentialMissedEnrollments {
  custom-mat-input {
    input {
      padding: 0px;
    }
  }
}

/* Custom Toastr Visuals for Neutral Toasts*/
.ngx-toastr {
  background-color: #313443 !important;
  padding: 15px !important;
  width: auto !important;
}

.toast-container * {
  flex-direction: row;
  display: flex;

  p {
    font-size: 13px !important;
    font-weight: 500 !important;
    color: colors.$white !important;
    font-family: 'roboto_slabregular', serif !important;
    padding-top: 5px !important;
  }

  h4, h5 {
    background-color: #00A538 !important;
    border-radius: 15px;
    padding: 2px 10px;
    margin-left: 10px !important;
    color: white !important;
    font-family: 'Work Sans' !important;
  }

  //Visuals for error
  h5 {
    background-color: #BD362F !important;
    margin-bottom: 0px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    letter-spacing: 1.75px;
    font-size: 14px;
  }
}

.toast-success {
  background-color: #51A351 !important;
  padding: 15px 15px 15px 50px !important;
}

.toast-error {
  background-color: #BD362F !important;
  padding: 15px 15px 15px 50px !important;
}



.filterMoveNotice {
  .mat-mdc-radio-button .mdc-radio {
    padding: calc((var(--mdc-radio-state-layer-size, 25px) - 20px) / 2) !important;
  }

  custom-mat-input-for-filter {
    mat-form-field {
      width: 88% !important;
      height: 40px !important;
    }

    input {
      padding: 0px !important;
    }
  }
}

.userDetails-Address {
  .mdc-floating-label--float-above {
    margin-top: 12px !important;
    margin-left: 10px !important;
  }

  input {
    padding-bottom: 5px !important;
    padding-top: 15px !important;
    padding-left: 15px;
    width: 98% !important;
  }

  input:focus {
    padding-left: 11px !important;
    width: 99% !important;
  }
}

//Checkbox overrides to reduce spacing between lines
.userDetails-page,
.myaccount-page {

  .mat-mdc-checkbox .mdc-checkbox {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
    top: 0;
  }

  .mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:not([disabled])~.mdc-checkbox__ripple {
    display: none;
  }

  .mat-mdc-checkbox-touch-target {
    height: 24px
  }

  .mdc-text-field--disabled.mdc-text-field--filled {
    background-color: colors.$grey2;
  }

  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:disabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
    background-color: rgba(0, 0, 0, 0) !important;
    color: white !important;
  }
}

//Radio button overrides to reduce spacing between lines
.pfs-state-radio-buttons,
.ccr-state-radio-buttons {
  .mat-mdc-radio-button .mdc-radio {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .mat-mdc-radio .mdc-radio .mdc-radio__background {
    top: 0;
  }

  .mat-mdc-radio .mdc-radio:hover .mdc-radio__native-control:not([disabled])~.mdc-radio__ripple {
    display: none;
  }

  .mat-mdc-radio-touch-target {
    height: 24px
  }
}

.userDetails-page {
  custom-mat-input {
    .mat-mdc-form-field .mat-mdc-floating-label[ng-reflect-floating='true'] {
      margin-top: 11px !important;
      margin-left: 5px !important;
    }

    input:focus,
    .mat-input-has-value {
      padding: 14px 0px 2px 11px;
      width: 99%;
    }
  }

  .extension {
    input {
      width: 97% !important;
      padding: 14px 0px 3px 11px !important;
    }
  }

  .input-tablet-portrait mat-form-field {
    width: 88% !important;
  }

  .input-tablet-portrait {
    width: 80% !important;
  }

  .input-tablet-landscape {
    width: 95% !important;
  }

  .activeDates {
    custom-mat-input {
      input {
        width: 97% !important;
        padding: 14px 0px 2px 11px !important;
      }
    }
  }

  @media screen and (max-width: 600px) {
    mat-form-field {
      width: 85% !important;
    }

    input {
      width: 98% !important;
    }

    .extension mat-form-field {
      width: 68% !important;
    }
  }

  @media screen and (min-width: 810px) {
    .extentsion mat-form-field {
      width: 88% !important;
    }

    .mat-input-has-value {
      width: 98% !important;
    }
  }

  @media screen and (min-width: 430px) and (max-width: 1023px) {

    .extension,
    .activeDates {
      input {
        width: 73% !important;
      }
    }

    .activeDates {
      mat-form-field {
        width: 80% !important;
      }
    }
  }

  .phone custom-mat-input {
    input {
      width: 97% !important;
      padding: 15px 0px 1px 11px !important;
    }

    mat-form-field {
      width: 100% !important;
    }
  }
}

.userAdmin-page {
  custom-mat-input {
    input {
      padding: 13px 0px 5px 10px !important;
      width: 98% !important;
    }

    .mat-mdc-form-field .mat-mdc-floating-label[ng-reflect-floating='true'] {
      margin-top: 8px !important;
      margin-left: 5px !important;
    }
  }
}

.dataAdmin-page .regionMembership .mdc-floating-label--float-above {
  display: none;
}

// For Checkboxes
mat-checkbox .mdc-checkbox__checkmark {
  color: colors.$green  !important;
  background-color: colors.$white  !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
  border-color: colors.$green  !important
}

.mat-mdc-checkbox .mdc-checkbox--anim-checked-unchecked {
  display: none;
}

.mat-mdc-checkbox .mdc-checkbox--anim-unchecked-checked {
  display: none;
}

//For Radio group and button
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-icon-color: #00A569 !important;
  --mdc-radio-selected-hover-icon-color: #00A569 !important;
  --mdc-radio-selected-pressed-icon-color: #00A569 !important;
  --mdc-radio-selected-focus-icon-color: #00A569 !important;
  --mdc-radio-unselected-hover-icon-color: rgba(0, 0, 0, 0.54) !important;
  --mat-mdc-radio-checked-ripple-color: #00A569 !important;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54) !important;
  --mat-mdc-radio-ripple-color: transparent !important;
}

//For mat-select
.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: #00A569;
}

/* .mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill .mdc-floating-label--float-above {
  margin-top: -5px;
}

.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill .mat-mdc-floating-label {
  margin-top: -5px;
} */

// Override for the selected text value in the mat-select boxes
::ng-deep .mat-mdc-select-value-text {
  font-family: 'Work Sans';
  white-space: nowrap !important;
  margin-left: 1px;
}

.move-notice-dropdown .mat-mdc-select-value-text {
  color: colors.$green;
}

.dataAdmin-page {

  .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
    display: none !important;
  }
}

// For mat-slide-toggle
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: rgb(0, 165, 105);
  --mdc-switch-selected-handle-color: rgb(0, 165, 105);
  --mdc-switch-selected-hover-state-layer-color: rgb(0, 165, 105);
  --mdc-switch-selected-pressed-state-layer-color: rgb(0, 165, 105);
  --mdc-switch-selected-focus-handle-color: rgb(0, 165, 105);
  --mdc-switch-selected-hover-handle-color: rgb(0, 165, 105);
  --mdc-switch-selected-pressed-handle-color: rgb(0, 165, 105);
  --mdc-switch-selected-focus-track-color: rgb(0, 165, 105, 0.5);
  --mdc-switch-selected-hover-track-color: rgb(0, 165, 105, 0.5);
  --mdc-switch-selected-pressed-track-color: rgb(0, 165, 105, 0.5);
  --mdc-switch-selected-track-color: rgb(0, 165, 105, 0.5);
}

md-switch.md-default-theme.md-checked:not([disabled]) .md-bar,
md-switch.md-checked:not([disabled]) .md-bar {
  background-color: rgba(0, 165, 105, 0.5);
}

.mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: rgb(0, 165, 105);
  --mdc-switch-unselected-hover-handle-color: rgb(0, 165, 105);
  --mdc-switch-unselected-pressed-handle-color: rgb(0, 165, 105);
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: rgba(0, 165, 105, 0.3);
}

.login-form {

  .mat-input-has-value,
  input:focus {
    width: 99% !important;
  }

  .box-selected,
  mat-form-field:focus-within {
    z-index: 100;
  }
}

.student-info-dates {
  .mdc-text-field__input {
    padding-top: 0px !important;
  }
}

.state-student-key-box {
  svg {
    margin-top: 10px;
    margin-left: -5px;
  }

  .mdc-line-ripple {
    display: none;
  }
}

.filterPFSIndicator {
  mat-form-field {
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  }

  mat-form-field:focus-within {
    border-bottom-color: colors.$green;
  }

  input {
    padding: 0px 20px 0px 0;
    box-sizing: border-box;
    margin-top: 15px;
  }

  input:focus,
  .mat-input-has-value {
    padding: 10px 20px 0px 10px !important;
  }

  .mdc-line-ripple {
    display: none;
  }

  label {
    margin-top: 15px !important;
  }

  .mat-mdc-form-field .mat-mdc-floating-label[ng-reflect-floating='true'] {
    margin-top: 10px;
    margin-left: 5px;
    font-weight: 400;
  }
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  overflow: hidden;
}


//introJS tooltip 
//container 
.introjs-tooltip {
  min-width: 570px !important;
}

.introjs-tooltip-header {
  padding-top: 0px !important;
  height: 0em !important;
  position: absolute !important;
  bottom: 313px;
  left: 40px;
}

//nav bullet styling
.introjs-bullets ul li a {
  margin: 5px !important;
  height: 10px !important;
  width: 10px !important;
}

.introjs-bullets ul li a.active {
  background: #00A569 !important;
}

//text styling
.introjs-title {
  font-family: 'roboto_slabregular' !important;
  font-size: 18px !important;
  color: #4A4A4A !important;
  letter-spacing: 0 !important;
  text-align: center !important;
  padding-top: 10px !important;
}

.introjs-text {
  padding: 20px 20px 0 20px !important;
  font-family: 'Work Sans' !important;
  font-size: 14px !important;
  color: #4A4A4A !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
}

.introjs-bold {
  font-weight: bold !important;
}

//toolbar button styling
.introjs-tooltipbuttons {
  display: flex !important;
  justify-content: space-between !important;
}

.introjs-button:nth-of-type(2) {
  margin-left: auto !important;
}

.introjs-fullbutton,
.introjs-button,
.introjs-prevbutton,
.introjs-nextbutton,
.introjs-prevbutton.introjs-fullbutton {
  background: #00A569 !important;
  color: colors.$white  !important;
  font-family: 'Work Sans';
  font-size: 15px !important;
  text-shadow: none !important;
  font-weight: 700 !important;
  border-radius: 20px !important;
  border: none !important;

  &:hover {
    color: colors.$white  !important;
    background: #00A569 !important;
  }

  &:focus {
    background: #00A569 !important;
    color: colors.$white  !important;
  }
}

.introjs-skipbutton:focus {
  border: 2px solid black;
}

.introjs-skipbutton,
.introjs-skipbutton.introjs-donebutton {
  background: colors.$white  !important;
  color: #00A569 !important;
  top: 278px !important;
  right: 494px !important;
  height: 40px !important;
  left: -30px !important;

  &:hover {
    background: colors.$white  !important;
    color: #00A569 !important;
    border: none !important;
    box-shadow: none !important;
  }
}

.introjs-prevbutton,
.introjs-nextbutton {
  padding: 8px 40px !important;

  .chevy {
    margin: 0 !important;
  }
}

.introjs-prevbutton {
  margin-left: 350px;
}

.introjs-nextbutton {
  margin-left: 10px !important;
}

.introjs-hidden {
  display: none !important;
}

.fa-chevron-right:before,
.fa-chevron-left:before {
  font-family: sans-serif, 'FontAwesome' !important;
}

.generalMoveTo, .generalMoveFrom {
  .mobile-view svg { 
    margin-top: 0px;
    margin-left: -25px;
    padding-left: 10px;
    border-left: 1px solid #d3d3d3;
    height: 68px;
    color: #00A569 !important;
    font-size: 16px;
  }
}

.studentRecordSearch-page {
  custom-mat-input {
    input {
      padding: 13px 0px 5px 10px !important;
      width: 98% !important;
    }

    .mat-mdc-form-field .mat-mdc-floating-label[ng-reflect-floating='true'] {
      margin-top: 10px;
      margin-left: 5px;
    }
  }
}

.export-filter,
.filterAccountList .dates,
.filterDataRequest .dates,
.filterMsixUsage .dates .recordsExchange .dates {

  input {
    width: 40px;
    padding-top: 0px !important;
  }

  .mat-mdc-checkbox .mdc-checkbox {
    padding-left: 5px;
  }

  label {
    white-space: normal;
  }

}

// Overrides after adding the theme - END

md-radio-button .md-on,
md-radio-button.md-default-theme .md-on {
  background-color: colors.$green  !important;
}

md-radio-button.md-checked .md-off,
md-radio-button.md-default-theme.md-checked .md-off {
  border-color: colors.$green  !important;
}

.input-container-margins {
  margin: 3px 0;
}

md-option .md-text {
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.md-select-value .md-text {
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  letter-spacing: 0.5px;
}

::ng-deep .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: #00A569 !important;
}

/*need to use !important to override the default color*/
::ng-deep .mat-mdc-option.mdc-list-item--selected {
  font-family: 'Work Sans';
  color: #00A569 !important;
}

.moveNotice,
.gradeRetention,
.potentialMissedEnrollments,
.move-notice-showing-box {
  .mat-mdc-select-arrow svg {
    margin-top: 0px;
  }
}

.newUserState .mat-mdc-select-arrow svg {
  margin-top: 5px;
}

.userDetails-state .mat-mdc-select-arrow svg {
  margin-top: 6px;
  margin-left: -13px;
}

.myaccount-page .challengeQuestions svg {
  margin-top: 5px;
}

//Changes default underline for mat-form-field
::ng-deep .mdc-line-ripple {
  display: none;
}

.placeholder-label {
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  color: colors.$grey5;
  letter-spacing: 0.5px;
  padding-bottom: 3px;
  margin-left: 10px;
}

@media only screen and (min-width: 600px) {

  .dashboard-module {
    border-style: solid;
    border-width: 1px;
    border-color: colors.$green;
    border-radius: 4px;
    background-color: colors.$grey1;
  }

  .dashboard-module-title-background {
    background: colors.$green;
    border-radius: 3px 3px 0 0;
  }

  .dashboard-module-title-text {
    color: colors.$white;
    padding: 0;
    margin: 0;
    font-weight: bold;
  }

  .dashboard-module-title-text-2 {
    padding: 6px;
    color: colors.$white;
    margin: 0;
    font-weight: bold !important;
  }

  a.dashboard-module-clear-text {
    padding: 0 8px 0 0 !important;
    margin: 0;
  }
}

@media only screen and (min-width: 600px) {
  .layout-padding-l {
    padding-left: 14px;
    padding-right: 10px;
  }

  .layout-padding-r {
    padding-right: 14px;
  }
}


.dashboard-module-clear-text {
  padding: 0 !important;
}

.search-form-label {
  color: colors.$grey4  !important;
  font-size: 17px !important;
  letter-spacing: 0.5px;
}

.worklist-module {
  border-style: solid;
  border-width: 1px;
  border-color: colors.$grey2;
  background-color: colors.$white;
}

/*IE Below*/
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .md-input {
    padding-top: 10px !important;
  }
}