mat-form-field {
    &:not(.text-area) {
        .mat-mdc-form-field-infix { height: 3.5rem;}
    }
    .mat-mdc-form-field-infix {
        padding-top: 0.125rem;
        padding-bottom: 0.125rem;
        display: flex;
        align-items: center;
        
    }

    .mdc-text-field--filled {
        background-color: inherit !important;
    }
    
    .mat-mdc-form-field-focus-overlay {
        background-color: transparent !important;
    }

}

.mdc-dialog__container {
    mat-form-field {
        .mdc-text-field--filled {
            background-color: white !important;
        }
    }
}