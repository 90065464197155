@use "./colors.scss";

  .mat-mdc-form-field-input-control {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 100%
  }

  .newUserState  .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
    margin-top: -3px !important;
  }
  .corDialog-recipient .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
    margin-top: 0px;
  }
  .COR-selected .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
    margin-top: 8px;
  }
  .checkbox-label .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
    margin-top: 8px !important;
  }
  .custom-mat-input-component-for-filter .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
    margin-top: -5px;
  }
  .state-student-key-box, .status {
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
      margin-top: -5px;
    }
  }
  .state-select-box .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
    margin-top: 3px;
  }

  .move-notice-showing-box .mat-mdc-form-field-infix {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 5px !important;
    min-height: 25px !important;
  }

  .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
    opacity: 0;
  }
  .mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label span {
    color: colors.$green !important;
    font-size: 13px;
  }

  .mat-form-field-invalid .mat-mdc-floating-label.mdc-floating-label span {
    color: colors.$red !important;
    font-size: 13px;
  }

  .filterAccountList, .accountQuestions .mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
    color: rgba(0,0,0,0.54);
  }
  .myaccount-page {
    .response {
      .mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label span {
        color: rgba(0,0,0,0.54) !important;
      }
      input:focus .mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label span {
        color: colors.$red !important;
      }
    }
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 9px;
    margin-right: -6px;
    padding-bottom: 0px;
    margin-left: -16px;
    
  }

  .metricsName .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 20px;
  }

  .corDialog-recipient {
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
      margin-top: -15px;
    }
  }
  .corDialog-district {
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
      margin-right: -10px;
      margin-top: -15px;
    }
  }

  mat-select {
    border-color: red;
    .mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
      color: colors.$black;
    }
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: inherit;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: inherit;
  }

  .mdc-text-field .mdc-text-field__input {
    caret-color: black;
  }

  ::ng-deep .mdc-floating-label--float-above {
    margin-top: 10px;
  }
  
.date-filter-input {
    display: flex;
    width: 30%;
    gap: 1rem;
    .mat-mdc-form-field:not(:last-child) {
      position: relative !important;

      &::after {
        content: "/";
        display: inline-block;
        font-size: 1.5rem;
        position: absolute;
        margin-top: 1.5rem;
        left: 99%;
        color: colors.$grey3;
      }
    }

    .mat-mdc-form-field {
      width: 2.3rem !important;
      max-height: fit-content !important;
      padding-right: .3rem;
    }

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
      &:focus-within {
        border-bottom: 0.125rem solid colors.$green !important;
      }
      margin: 0px !important;
    }

    .mdc-text-field {
      padding: 0px !important;
      
    }

    .mat-mdc-form-field:last-child {
      width: 3.3rem !important;
    }

    // this keeps label green when it is lifted - need to figure out how to only keep it green when focused [mdev]
    .mat-mdc-form-field .mat-mdc-floating-label:not([ng-reflect-floating='false']) {
      color: colors.$green !important;
    }
    .mat-mdc-form-field .mat-mdc-floating-label[ng-reflect-floating='false'] {
      color: rgba(0,0,0,0.38) !important;
    }

    input:focus, .mat-input-has-value {
      padding: 0 !important;
      width: 100% !important;
    }

    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
      padding: 0 !important;
      padding-top: 1.8rem !important;
      
      text-align: center !important;
      min-height: 0 !important;
    }
  }
    