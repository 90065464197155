@import "./colors.scss";
.table {
  margin-top: 15px;
  border: 1px solid $grey2;
  .tableRow {
    display: flex;
    align-items: center;
    padding: 18px 10px;
    margin-left: 10px;
    margin-right: 10px;
    border-bottom: 1px solid $grey2;

    .mobile-item-padding{
      padding-right: 6px;
    }
  }
  .headerRow{
    padding: 13px 10px 13px 20px;
    margin-left: 0;
    margin-right: 0;
  }
  :last-child {
    border-bottom: 0;
  }
  block2 {
    line-height: 15px;
  }
}

.tableHeader{
  margin-bottom: 0;
  border-bottom: 2px solid $green;
}

.tableBody {
  border-top: 0;
  margin-top: 0;
  .enrollmentTableRow {
    display: block;
    padding: 13px 10px;
  }
}

.removeBorders{
  .tableHeader{
    border: 0;
    border-bottom: 2px solid $grey2;
    .tableRow{
      padding-left: 15px;
      padding-bottom: 10px;
    }
    .fa-minus{
      display: none;
    }
  }
  .tableBody{
    border: 0;
    .tableRow{
      padding: 13px 10px;
      padding-left: 2px;
      margin-left: 6px;
    }
    p{
      font-size: 15px;
    }
  }
  .qualInfoTable{
    padding-left: 15px;
  }
}

.table2Row{
  margin: 0 8px;
  border-bottom: 1px solid $grey2;
  p{
    font-size: 15px;
  }
  i{
    color: $grey3;
    margin-left: 0px;
  }
  .expandColumn-green{
    background-color: $white;
    i{
      color: $green;
    }
  }
  .section1{
    padding: 15px 0 15px 6px;
  }
  .section2{
    padding: 15px 0 15px 6px;
    border-top: 1px solid $grey2;
    background-color: $grey1;
    display: flex;
    p{
      font-size: 13px;
    }
    .col1{
      p{
        line-height: 15px;
        &.bold{
          font-weight: 600;
        }
      }
      .padTop{
        padding-top: 15px;
      }
    }
  }
}

.activeOutline{
  border: 2px solid $green;
}
.greyBottomBorder{
  border-bottom: 2px solid $grey2;
}
.greyBottomBorderThin{
  border-bottom: 1px solid $grey2;
}

.newtable2Row{
  /* margin: 0 8px; */
  background: $white;
  p{
    font-size: 15px;
  }
  i{
    color: $grey3;
    margin: 0px;
  }
  .idTitlePadding {
    padding-top: 20px;
    padding-bottom: 4px;
  }
  .enrItemDataText {
    font-weight: 400;
  }
  .enrItemText {
    font-weight: 700 !important;
    padding-bottom: 10px;
  }
  .nested-item-title{
    padding-left: 10px !important;
    font-weight: 700 !important;
    font-size: 13px !important;
  }
  .tableTextBottomBorder {
    padding-bottom: 10px;
  }
  .noSelectMargin {
    margin-top: 0;
    margin-bottom: 20px;
  }
  .demographicsColumn{
    margin-top: 10px;
  }
  .borderBottomGreen{
    border-bottom: 1px solid $green;
  }
  .borderBottomWhite2px{
    border-bottom: 2px solid $white;
  }
  .borderBottomGreen2px{
    border-bottom: 2px solid $green;
  }
  .borderBottomGrey{
    border-bottom: 1px solid $grey2;
  }
  .borderLightGreyOpen{
    border-top: 2px solid $grey2;
    border-left: 2px solid $grey2;
    border-right: 2px solid $grey2;
  }
  .borderTopLightGrey{
    border-top: 2px solid $grey2;
  }
  .borderBottomLightGrey{
    border-bottom: 2px solid $grey2;
  }
  .enrCenterBorder{
    border-left: 2px solid $grey2;
  }
  .borderLightGreyClosed{
    border-top: 2px solid $grey2;
    border-left: 2px solid $grey2;
    border-right: 2px solid $grey2;
  }
  .greyBackground {
    background-color: $grey1;
  }
  .dataAdminList {
    padding-left: 6px;
  }
  .expandColumn-green{
    background-color: $white;
    cursor: pointer;
    i{
      color: $green;
    }
  }
  .expandColumn-white{
    cursor: pointer;
    height: 50px;
    i{
      color: $grey3;
    }
  }
  .section1{
    padding: 15px 0 15px 6px;
  }
  .section2{
    padding: 15px 0 15px 6px;
    border-top: 1px solid $grey2;
    background-color: $grey1;
    display: flex;
    p{
      font-size: 13px;
    }
    .col1{
      p{
        line-height: 15px;
        &.bold{
          font-weight: 600;
        }
      }
      .padTop{
        padding-top: 15px;
      }
    }
  }
  .interiorContainer{
    background-color: $grey1;
  }
  .column{
    padding: 0 5px;
  }

  .subAccordion{
    margin-top: 30px;
    margin-bottom: 30px;
    .subAccordionPadding{
      padding-top: 10px;
    }
    .subAccordionMargin{
      margin-top: 10px;
    }
    .subAccordionBackground{
      background-color: $white;
    }
    .nested-items-container{
      padding-bottom: 20px;

      .nested-record-item{
        background-color: $light-green;
      }
    }

    .subAccordionBorderBottom{
      border-bottom: 2px solid $grey3;
    }
  }
}

.headerTitles{
  border-bottom: 1px solid $grey2;
  padding-left: 15px;
  margin-bottom: 0px;
}

td.container > div {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

td.container {
  height: 20px;
}

table {
  border-collapse: collapse;
}

tr.border-bottom {
  border-bottom: 1pt solid $grey2;
}

tr.border-top {
  border-top: 1pt solid $grey2;
}

.table-width {
  position: relative;
  width: 100%;
  display: table;
}

.table-align {
  text-align: left;
}
