@use "./colors.scss";

.reportsFilter {
  display: flex;
  margin: 0 auto;
  /* max-height: calc(100% - 150px); */
  overflow-y: auto;
  z-index: 110;
  width: 100%;
  border-radius: 4px 4px 0px 0px;

  .filter-title {
    color: colors.$white  !important;
    font-size: 20px !important;
  }

  .filter-section {
    margin: 10px 0;

    .layout-row {
      position: relative;
    }
  }

  .filter-dialog {
    width: 100%;
    /* margin   : 15px auto; */

    .start-date-box {
      margin-right: 5px;

      input[type="text"] {
        &:disabled {
          cursor: not-allowed;
          position: relative;
          border-color: rgba(0, 0, 0, 0.12);
          z-index: 100;
          background: colors.$white;
          opacity: 0.7;
        }
      }
    }

    .end-date-box {
      margin-left: 5px;

      input[type="text"] {
        &:disabled {
          cursor: not-allowed;
          position: relative;
          border-color: rgba(0, 0, 0, 0.12);
          z-index: 100;
          background: colors.$white;
          opacity: 0.7;
        }
      }
    }

    .date-slash {
      font-size: 20px;
    }

    .date-picker {
      md-input-container:not(.md-input-invalid) {
        &.md-input-focused .md-input {
          border-color: colors.$black;
        }

        &.md-input-focused label {
          color: colors.$black;
        }
      }
    }

    .thickHr {
      margin: 20px 0;
      height: 1px;
    }

    .md-toolbar-tools {
      padding: 0 30px;
    }

    .mat-toolbar-tools {
      padding: 0 30px;
    }

    .md-dialog-content {
      padding: 30px;
    }

    .mat-dialog-content {
      padding: 30px;
    }

    md-dialog-actions {
      padding: 15px 30px;
    }

    .md-dialog-actions {
      padding: 15px 30px;
      align-items: center;
    }

    mat-dialog-actions {
      padding: 15px 30px;
    }
  }

  .md-input-container {
    margin: 0;
    padding: 0;
  }

  .selectButtons {
    font-size: 15px;
    margin: 20px 0 0;

    & a {
      padding: 0 5px 0 7px;
      font-size: 15px;
    }
  }

  .colPOC {
    margin: 5px 0 0;
    height: 200px;
    overflow-y: scroll;
    border: 1px colors.$grey2 solid;
    padding: 20px;
  }

  .openEnroll {
    width: 70%;
    float: right;
    position: absolute;
    right: 0;
  }

  .enrollBut {
    width: calc(70% - 13px);
    float: right;
    position: fixed;
    right: 0;
  }

  .md-input-focused {
    label {
      color: green !important;
    }

    .md-input {
      border-color: colors.$green  !important;
    }
  }
}

.filterMoveFrom, .filterMoveTo {
  custom-mat-input-for-filter {
    input {
      padding: 0 !important;
    }
  }
}